export const getMenuStyles = (menuOpened) => {
  if (document.documentElement.clientWidth <= 800) {
    return { left: !menuOpened && "-100%"}
  }
}

export const sliderSettings = {
  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    480: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 1
    },
    750: {
      slidesPerView: 3
    },
    900: {
      slidesPerView: 4,
    },
  },
};