import React from "react";
import "./Milestones.css";
import { motion } from "framer-motion";

const Milestones = () => {
  return (
    <motion.div className="flexColCenter milestoneContianer">
      <motion.div className="flexStart titleBox">1992-2003</motion.div>
      <motion.div className="flexCenter contentBox">
        Logistic Service contracts with Embassies and UN agencies for logistic
      </motion.div>
    </motion.div>
  );
};

export default Milestones;
