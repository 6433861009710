import React from 'react'
import './Hero.css'

const Hero = () => {
  return (
    <div className="heroWrapper">
      <img src="./assets/images/elements/HomePageCoverRE02.png" alt="Cover"/>
    </div>
  )
}

export default Hero