import React from "react";
import "./MissionVision.css";

const MissionVision = () => {
  return (
    <section className="mvWrapper">
      <div className="innerWidth flexCenter mvContainer">
        <div className="flexColCenter mvVision">
          <h1 className="boldText mvTitle">Vision</h1>
          <p>
            To be number one diverse solution supplier, launching pioneer
            projects in Cambodia that will reflect on both the group and the
            country.
          </p>
        </div>
        <div className="flexColCenter mvMission">
          <h1 className="boldText mvTitle">Mission</h1>
          <p>
            To provide an excellent international reputation for maintaining the
            highest standards for operational excellence and group companies.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
