import React from "react";
import "./Aboutus.css";

const Aboutus = () => {
  return (
    <div className="aboutusWrapper">
      <div className="innerWidth aboutusCover">
        <img src="./assets/images/elements/AboutUsCover.jpg" alt="Cover" />
      </div>
      <div className="flexCenter aboutusContainer">
        <div className="flexCenter aboutUsDetail">
          <div className="flexColStart aboutusTitle">
            <h1 className="boldText aboutUsTitleText">About Us</h1>
            <div className="hl"></div>
          </div>
          <p className="aboutUsInfo">
            WorldBridge opened our doors in 1992 and has since grown into a top
            choice for international partners in the Cambodian market. We have
            developed partnerships with major global brands and companies,
            bringing investment and contributing to economic growth in the
            Kingdom. Through relationships built on trust, transparency, and
            proven success, WorldBridge is considered a leading group in
            Cambodia’s ever-evolving economy. <br /> <br /> Today, World Bridge
            Group has an international reputation for maintaining the highest
            standards of operational excellence and corporate social
            responsibility. In 2020, The Peak, a $580M (USD), 55-story
            three-tower complex, will bring the Shangri-La hotel and a new
            standard to the Phnom Penh skyline. The Palms, a $100M (USD) resort
            complex on the river, will also open in 2020. These projects
            continue the trend set by the World Bridge when launching The Bridge
            in 2018, a 45-story development in partnership with Oxley Singapore.{" "}
            <br /> <br /> In addition to commercial success, WorldBridge Group
            actively pursues investments that build national industries and
            increase standards of living for ordinary Cambodians. WorldBridge is
            proud of our investment in the Kingdom’s first affordable housing
            development, SME development cluster, and green fleet management.
          </p>
        </div>
        <div className="flexColStart aboutUsChairmanDetail">
          <div className="flexColStart aboutusTitle">
            <h1 className="boldText aboutUsTitleText">Our Chairman</h1>
            <div className="hl"></div>
          </div>
          <div className="flexCenter aboutUsChairman">
            <div className="chairmanPhoto">
              <img src="./assets/images/elements/chairman.jpg" alt="chairman" />
            </div>
            <div className="chairmanWords">
              Neak Oknha Rithy Sear is the founder and chairman of WorldBridge
              International Group, which holds more than thirty companies across
              a diversity of sectors including logistics, property development,
              technology, food and beverage, media, hospitality, e-commerce,
              banking, and security. Born in Phnom Penh in 1972 to a Chinese
              Khmer family, Rithy survived the Khmer Rouge regime to build the
              WorldBridge group from the ground up. Rithy first began his
              company in logistics, and World Bridge joint ventures with CEVA
              and Kerry Logistics remain one of the group’s core functions.{" "}
              <br />
              Personally, Rithy has also launched the country’s first
              significant venture capital fund, Ooctane, which has $55M (USD)
              under management for investment in technology startups and SMEs.
              Rithy’s personal investments also include ventures throughout the
              region, including those in Singapore, Myanmar, Vietnam, and Laos
              across various sectors. His charitable works in Cambodia include
              numerous public-private partnership programs, the Cambodian Red
              Cross, and the Mekong Futures Initiative (MFI). In 2020, Rithy was
              granted the title of “Neak Oknha” by the King of Cambodia in
              recognition of his status as a leader in the community.
            </div>
          </div>
        </div>
        <div className="flexColStart aboutUsMilestone">
          <div className="flexColStart aboutusTitle">
            <h1 className="boldText aboutUsTitleText">Milestone</h1>
            <div className="hl"></div>
          </div>
          <div className="milestoneImage">
            <img src="./assets/images/elements/milestone.png" alt="milestone" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
