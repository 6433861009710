import React from "react";
import "./Home.css";
import Hero from "../../Components/Hero/Hero";
import MissionVision from "../../Components/MissionVision/MissionVision";
import AboutHomePage from "../../Components/AboutHomePage/AboutHomePage";
import Businesses from "../../Components/Businesses/Businesses";
import News from "../../Components/News/News";

const Home = () => {
  return (
    <div className="homeWrapper">
      <div className="innerWidth flexCenter homeContainer">
        <Hero />
        <MissionVision />
        <AboutHomePage />
        <Businesses />
        <News />
      </div>
    </div>
  );
};

export default Home;
