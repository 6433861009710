import React from "react";
import "./Category.css";
import data from "../../Utils/Companies.json";

const Category = () => {
  return (
    <section className="flexCenter categoryWrapper">
      {data.map((i) => (
        <div className="flexCenter categoryContainer" key={i}>
          <div className="flexcenter categoryTitle">
            <h1 className="boldText">{i.category}</h1>
          </div>
          <div className="flexColStart categoryDetail">
            <div className="categoryText">
              {i.Des}
            </div>
            <div className="flexStart categoryLogo">
              {i.Logo.map((e)=>(
                <div className="categoryLogoImg">
                  <img src={e} alt={e} key={e}/>
                </div>
              ))}
            </div>
            <div className="hl"></div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Category;
